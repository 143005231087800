/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";

import Nav2 from "./Nav2";
import Banner0 from "./Banner0";
import Content13 from "./Content13";
import Feature1 from "./Feature1";
import Feature2 from "./Feature2";
import Feature0 from "./Feature0";
import Content5 from "./Content5";
import Teams1 from "./Teams1";
import Footer1 from "./Footer1";

import {
  Nav20DataSource,
  Banner01DataSource,
  Content130DataSource,
  Feature10DataSource,
  Feature20DataSource,
  Content131DataSource,
  Feature11DataSource,
  Feature21DataSource,
  Feature00DataSource,
  Content50DataSource,
  Content132DataSource,
  // Teams10DataSource,
  // Feature01DataSource,
  Footer12DataSource,
} from "./data.source";
import "./less/antMotionStyle.less";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== "undefined" ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Nav2
        id="Nav2_0"
        key="Nav2_0"
        dataSource={Nav20DataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner0
        id="Banner0_1"
        key="Banner0_1"
        dataSource={Banner01DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content13
        id="Content13_2"
        key="Content13_2"
        dataSource={Content132DataSource}
        isMobile={this.state.isMobile}
        style={{
          color: "#fff",
          backgroundImage:
            "url(https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg)",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
        }}
      />,
      <Content13
        id="Content13_0"
        key="Content13_0"
        dataSource={Content130DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature1
        id="Feature1_0"
        key="Feature1_0"
        dataSource={Feature10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature2
        id="Feature2_0"
        key="Feature2_0"
        dataSource={Feature20DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content13
        id="Content13_1"
        key="Content13_1"
        dataSource={Content131DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature1
        id="Feature1_1"
        key="Feature1_1"
        dataSource={Feature11DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature2
        id="Feature2_1"
        key="Feature2_1"
        dataSource={Feature21DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature0
        id="Feature0_0"
        key="Feature0_0"
        dataSource={Feature00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content5
        id="Content5_0"
        key="Content5_0"
        dataSource={Content50DataSource}
        isMobile={this.state.isMobile}
      />,
      /*<Teams1
        id="Teams1_0"
        key="Teams1_0"
        dataSource={Teams10DataSource}
        isMobile={this.state.isMobile}
      />,*/
      // <Feature0
      //   id="Feature0_1"
      //   key="Feature0_1"
      //   dataSource={Feature01DataSource}
      //   isMobile={this.state.isMobile}
      //   style={{
      //     color: '#fff',
      //     backgroundImage: 'url(https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg)',
      //     backgroundSize: 'cover',
      //     backgroundAttachment: 'fixed',
      //     backgroundPosition: 'center',
      //   }}
      // />,
      <Footer1
        id="Footer1_2"
        key="Footer1_2"
        dataSource={Footer12DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
