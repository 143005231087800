import React from 'react';
import logo from './img/logo.svg';
import aliyun from './img/aliyun.svg';
import txyun from './img/txyun.svg';
import train from './img/train.svg';
import legao from './img/legao.svg';
import sn from './img/sn.jpg';
import nfkd from './img/nfkd.png';
import asz from './img/asz.jpg';
import gyzj from './img/gyzj.png';
import zhgd from './img/zhgd.jpg';
import tlpt from './img/tlpt.png';
import xlpt from './img/xlpt.png';
import yl from './img/yl.jpg';
import zxc from './img/zxc.png';
import lzf from './img/lzf.png';
import chs from './img/chs.png';
import qrcode from './img/qrcode.jpg';

export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: {
    className: 'header2 home-page-wrapper kw8ov9mrez-editor_css'
  },
  page: {
    className: 'kw8ovdhy60f-editor_css home-page'
  },
  logo: {
    className: 'header2-logo',
    children: logo,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [{
        name: 'linkNav',
        to: 'Banner0_1',
        children: '首页',
        className: 'menu-item kw8p05mvxgk-editor_css',
      },

      {
        name: 'linkNav~kw8p12e5t8p',
        to: 'Content13_2',
        children: '关于我们',
        className: 'menu-item kw8p05mvxgk-editor_css',
      },
      {
        name: 'linkNav~kw8p09h9zh',
        to: 'Feature1_0',
        children: '解决方案',
        className: 'menu-item kw8p05mvxgk-editor_css',
      },
      {
        name: 'linkNav~kw8p10enexl',
        to: 'Feature1_1',
        children: '产品介绍',
        className: 'menu-item kw8p05mvxgk-editor_css',
      },
      {
        name: 'linkNav~kw8p11wzif',
        to: 'Feature0_0',
        children: '合作共赢',
        className: 'menu-item kw8p05mvxgk-editor_css',
      },
    ],
  },
  mobileMenu: {
    className: 'header2-mobile-menu'
  },
};
export const Banner01DataSource = {
  wrapper: {
    className: 'banner0'
  },
  textWrapper: {
    className: 'banner0-text-wrapper'
  },
  title: {
    className: 'banner0-title',
    children: logo,
  },
  content: {
    className: 'banner0-content',
    children: ( <
      span >
      <
      p > 普及AI应用为行业赋能， 让每个企业享受到AI创造的价值 < /p><
      p > 人工智能视觉算法 < /p><
      p > 智慧工地 / 安防 / 工业质检 < /p> < /
      span >
    ),
  },
  button: {
    className: 'banner0-button kw8p6r81geq-editor_css',
    children: ( <
      span >
      <
      span >
      <
      span >
      <
      p > 知悉更多 < /p> < /
      span > <
      /span> < /
      span >
    ),
    type: 'primary',
    href: '#Content13_0',
  },
};

export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{
        name: 'title',
        children: ( <
          span >
          <
          span >
          <
          span >
          <
          p > 解决方案 < /p> < /
          span > <
          /span> < /
          span >
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: ( <
          span >
          <
          span >
          <
          span >
          <
          p >
          公司推出的算法推理落地整体解决方案， 致力于开拓人工智能在传统行业的开发与应用、 为行业赋能， 使传统产业向人工智能转型升级 <
          /p> < /
          span > <
          /span> < /
          span >
        ),
        className: 'title-content kw8pf8i3l3-editor_css',
      },
      {
        name: 'content2',
        children: ( <
          span >
          <
          p > “普及AI应用为行业赋能， 让每个企业享受到AI创造的价值” 是时间量子的使命 <
          /p> < /
          span >
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper'
  },
  OverPack: {
    className: 'home-page content1',
    playScale: 0.3
  },
  imgWrapper: {
    className: 'content1-img',
    md: 10,
    xs: 24
  },
  img: {
    children: gyzj,
  },
  textWrapper: {
    className: 'content1-text',
    md: 14,
    xs: 24
  },
  title: {
    className: 'content1-title',
    children: ( <
      span >
      <
      p > 工业质检 < /p> < /
      span >
    ),
  },
  content: {
    className: 'content1-content',
    children: ( <
      span >
      <
      p >
      传统质检更多依靠的是人工劳动方式分拣， 仍然带来一系列问题， 包括效率低、 审核质量不可控、 人力成本高等， 目前已经成为传统工业垂直领域的瓶颈。 为了根本性解决能效、 质量、 成本问题， 基于视觉算法为核心、 实现并提供了一套定制化的AI软硬一体化解决方案， 质检全过程(自动上料、 检测、 剔除、 重检等) 无须人工参与即可全自动化质检。 通过AI赋能， 推进工业4 .0 时代变革， 助力制造业流程改善、 重塑工业领域企业核心竞争力。 <
      /p> < /
      span >
    ),
  },
};
export const Feature20DataSource = {
  wrapper: {
    className: 'home-page-wrapper content2-wrapper'
  },
  OverPack: {
    className: 'home-page content2',
    playScale: 0.3
  },
  imgWrapper: {
    className: 'content2-img',
    md: 10,
    xs: 24
  },
  img: {
    children: zhgd,
  },
  textWrapper: {
    className: 'content2-text',
    md: 14,
    xs: 24
  },
  title: {
    className: 'content2-title',
    children: ( <
      span >
      <
      span >
      <
      p > 智慧工地 < /p> < /
      span > <
      /span>
    ),
  },
  content: {
    className: 'content2-content',
    children: ( <
      span >
      <
      p >
      基于工地智能化管理模式， 利用视觉算法赋能， 打造以安全生产为基础的智慧工地体系， 全面提升安全作业、 管理效率， 助力工地实现智能化升级改造， 让工地走向可视化、 数字化、 智能化。 基于人脸识别、 安全帽识别算法、 反光衣算法、 火焰算法、 烟雾算法、 深基坑算法、 抽烟识别算法、 工人打架算法等， 为智慧工地场景提供了实名考勤、 安全作业、 安全监控分析等提供了完整的一套解决方案， 颠覆传统工地管理模式， 提供了有效的管理辅助工具平台， 24 小时不间断地实时监控与管理。 <
      /p> < /
      span >
    ),
  },
};
export const Content131DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{
      name: 'title',
      children: ( <
        span >
        <
        p > 产品介绍 < /p> < /
        span >
      ),
      className: 'title-h1',
    }, ],
  },
};
export const Feature11DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper'
  },
  OverPack: {
    className: 'home-page content1',
    playScale: 0.3
  },
  imgWrapper: {
    className: 'content1-img',
    md: 10,
    xs: 24
  },
  img: {
    children: xlpt,
  },
  textWrapper: {
    className: 'content1-text',
    md: 14,
    xs: 24
  },
  title: {
    className: 'content1-title',
    children: ( <
      span >
      <
      p > 推理平台 < /p> < /
      span >
    ),
  },
  content: {
    className: 'content1-content',
    children: ( <
      span >
      <
      p >
      推理平台是时间量子科技推出的一站式算法推理部署平台， 以计算机视觉算法为核心， 为企业提供标准化的算法交付解决方案， 致力于帮助目标客户快速方便的部署、 管理应用。 <
      /p> < /
      span >
    ),
  },
};
export const Feature21DataSource = {
  wrapper: {
    className: 'home-page-wrapper content2-wrapper'
  },
  OverPack: {
    className: 'home-page content2',
    playScale: 0.3
  },
  imgWrapper: {
    className: 'content2-img',
    md: 10,
    xs: 24
  },
  img: {
    children: tlpt,
  },
  textWrapper: {
    className: 'content2-text',
    md: 14,
    xs: 24
  },
  title: {
    className: 'content2-title',
    children: ( <
      span >
      <
      p > 训练平台 < /p> < /
      span >
    ),
  },
  content: {
    className: 'content2-content',
    children: ( <
      span >
      <
      p >
      时间量子自研的训练平台， 拥有双重引擎， 即能满足企业日常开发与训练， 又能切换到教学模式， 方便高校教学科研使用 <
      /p> < /
      span >
    ),
  },
};
export const Feature00DataSource = {
  wrapper: {
    className: 'home-page-wrapper content0-wrapper'
  },
  page: {
    className: 'home-page content0 kw8puqvo4bo-editor_css'
  },
  OverPack: {
    playScale: 0.3,
    className: ''
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{
        name: 'title',
        children: ( <
          span >
          <
          p > 合作共赢 < /p> < /
          span >
        ),
      },
      {
        name: 'content~kw8ps02f2nk',
        className: '',
        children: ( <
          span >
          <
          p >
          合作共赢， 持续发展是时间量子的企业文化， 愿与志同道合的合作伙伴一起构建合作共赢渠道生态体系， 提供全方位的专业技术及区域资源支持， 一起开拓人工智能蓝海市场， 创造更多价值 <
          /p> < /
          span >
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [{
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [{
              name: 'image',
              className: 'content0-block-icon',
              children: 'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: ( <
                span >
                <
                p > 方案融合 < /p> < /
                span >
              ),
            },
            {
              name: 'content',
              children: ( <
                span >
                <
                span >
                <
                span >
                <
                p style = {
                  {
                    fontSize: 18
                  }
                } >
                授权合作伙伴使用时间量子标准解决方案、 算法演示视频 / 图片等， 协助合作伙伴进行解决方案整合 <
                /p> < /
                span > <
                /span> < /
                span >
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [{
              name: 'image',
              className: 'content0-block-icon',
              children: 'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: ( <
                span >
                <
                p > 技术支持 < /p> < /
                span >
              ),
            },
            {
              name: 'content',
              children: ( <
                span >
                <
                span >
                <
                p style = {
                  {
                    fontSize: 18
                  }
                } >
                根据不同合作形式， 提供多方面技术支持及服务， 如售前咨询、 方案优化、 技术文档、 技术顾问、 底层架构等支持 <
                /p> < /
                span > <
                /span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [{
              name: 'image',
              className: 'content0-block-icon',
              children: 'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: ( <
                span >
                <
                p > 项目合作 < /p> < /
                span >
              ),
            },
            {
              name: 'content',
              children: ( <
                span >
                <
                p style = {
                  {
                    fontSize: 18
                  }
                } >
                针对重要客户重点支持与陪访， 专家协助现场支持产品方案讲解， 帮助伙伴赢得项目 <
                /p> < /
                span >
              ),
            },
          ],
        },
      },
      {
        name: 'block~kw8psqx8iwl',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [{
              name: 'image',
              className: 'content0-block-icon',
              children: 'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: ( <
                span >
                <
                p > 商机共享 < /p> < /
                span >
              ),
            },
            {
              name: 'content',
              children: ( <
                span >
                <
                p style = {
                  {
                    fontSize: 18
                  }
                } >
                为合作伙伴优先引荐相关行业客户或项目机会， 实现与合作伙伴商机共享， 合作共赢 <
                /p> < /
                span >
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: {
    className: 'home-page-wrapper content5-wrapper'
  },
  page: {
    className: 'home-page content5'
  },
  OverPack: {
    playScale: 0.3,
    className: ''
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{
      name: 'title',
      children: ( <
        span >
        <
        p > 合作伙伴 < /p> < /
        span >
      ),
      className: 'title-h1',
    }, ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [{
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content'
          },
          img: {
            children: aliyun,
          },
          content: {
            children: ( <
              span >
              <
              p > 阿里云 < /p> < /
              span >
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content'
          },
          img: {
            children: txyun,
          },
          content: {
            children: ( <
              span >
              <
              p > 腾讯云 < /p> < /
              span >
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content'
          },
          img: {
            children: 'https://www.szairport.com/szairport/xhtml/images/logo-ny.svg',
          },
          content: {
            children: ( <
              span >
              <
              span >
              <
              span >
              <
              p > 深圳机场 < /p> < /
              span > <
              /span> < /
              span >
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content'
          },
          img: {
            children: train,
          },
          content: {
            children: ( <
              span >
              <
              p > 深圳高铁 < /p> < /
              span >
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content'
          },
          img: {
            children: asz,
          },
          content: {
            children: ( <
              span >
              <
              p > 埃森哲 < /p> < /
              span >
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content'
          },
          img: {
            children: nfkd,
          },
          content: {
            children: ( <
              span >
              <
              p > 南方科技大学 < /p> < /
              span >
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content'
          },
          img: {
            children: legao,
          },
          content: {
            children: ( <
              span >
              <
              p > 乐高 < /p> < /
              span >
            ),
          },
        },
      },
    ],
  },
};
export const Content132DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{
        name: 'title',
        children: ( <
          span >
          <
          p style = {
            {
              color: '#fff',
            }
          } > 关于我们 < /p> < /
          span >
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: ( <
          span >
          <
          span >
          <
          span >
          <
          p style = {
            {
              fontSize: 22
            }
          } >
          深圳市时间量子科技有限公司， 是一家专注于 < span style = {
            {
              color: '#6395f9'
            }
          } > 人工智能视觉算法 < /span>的初创型科技企业。 < br / >
          <
          /p> < /
          span > <
          /span> < /
          span >
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: ( <
          span >
          <
          p style = {
            {
              padding: '20px 15%',
              fontSize: 22
            }
          } > “ < span style = {
            {
              color: '#6395f9'
            }
          } > 普及AI应用为行业赋能， 让每个企业享受到AI创造的价值 < /span>” 是时间量子的使命。 公司推出的算法推理落地整体解决方案， 致力于开拓人工智能在传统行业的开发与应用、 为行业赋能， 使传统产业向人工智能转型升级。 < /
          p > < /
          span >
        ),
        className: 'title-content kw8u39ikdr6-editor_css',
      },
      {
        name: 'content~kw8u3hntx8n',
        className: '',
        children: ( <
          span >
          <
          p style = {
            {
              padding: '0 15%',
              fontSize: 22
            }
          } >
          时间量子是一家年轻的企业， 由一群富有梦想的年轻伙伴组成， 同时公司团队拥有多年丰富的人工智能算法开发、 落地应用经验； 目前积累了丰富的 < span style = {
            {
              color: '#6395f9'
            }
          } > 智慧工地、 安防、 工业质检等领域的优质算法 < /span>； 并持续专注视觉核心算法的研发和积累， 不断为行业创造更多的价值。 < /
          p > < /
          span >
        ),
      },
    ],
  },
};
export const Feature01DataSource = {
  wrapper: {
    className: 'home-page-wrapper content0-wrapper'
  },
  page: {
    className: 'home-page content0'
  },
  OverPack: {
    playScale: 0.3,
    className: ''
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{
      name: 'title',
      children: ( <
        span >
        <
        p style = {
          {
            color: '#fff',
          }
        } > 企业文化 < /p> < /
        span >
      ),
    }, ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [{
        name: 'block0',
        className: 'content0-block kw8u6ny9a7d-editor_css',
        md: 24,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [{
            name: 'title',
            className: 'content0-block-title',
            children: ( <
              span >
              <
              p style = {
                {
                  color: '#fff',
                }
              } > 不甘平庸， 追求卓越 < /p> < /
              span >
            ),
          }, ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block kw8u6rj1xwe-editor_css',
        md: 24,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [{
            name: 'title',
            className: 'content0-block-title',
            children: ( <
              span >
              <
              p style = {
                {
                  color: '#fff',
                }
              } > 平等透明， 高效互补 < /p> < /
              span >
            ),
          }, ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block kw8u6syfwg-editor_css',
        md: 24,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [{
            name: 'title',
            className: 'content0-block-title',
            children: ( <
              span >
              <
              p style = {
                {
                  color: '#fff',
                }
              } > 合作共赢， 持续发展 < /p> < /
              span >
            ),
          }, ],
        },
      },
      {
        name: 'block~kw8u6ovk9tf',
        className: 'content0-block kw8u6wdfqj8-editor_css',
        md: 24,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [{
            name: 'title',
            className: 'content0-block-title',
            children: ( <
              span >
              <
              span >
              <
              p style = {
                {
                  color: '#fff',
                }
              } > 健康工作， 健康生活 < /p> < /
              span > <
              /span>
            ),
          }, ],
        },
      },
    ],
  },
};
export const Footer12DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer1-wrapper'
  },
  OverPack: {
    className: 'footer1',
    playScale: 0.2
  },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [{
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children: logo,
        },
        childWrapper: {
          className: 'slogan',
          children: [{
            name: 'content0',
            children: ( <
              span >
              <
              p > 普及AI应用为行业赋能， 让每个企业享受到AI创造的价值 < /p> < /
              span >
            ),
          }, ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 12,
        className: 'block',
        title: {
          children: ( <
            span >
            <
            p > 公司信息 < /p> < /
            span >
          ),
        },
        childWrapper: {
          children: [{
              name: 'link0',
              href: '#',
              children: ( <
                span >
                <
                p > 邮箱： sales @timeqt.com < /p> < /
                span >
              ),
            },
            {
              name: 'link1',
              target: '_blank',
              href: 'https://www.amap.com/search?query=%E6%B7%B1%E5%9C%B3%E5%B8%82%E5%8D%97%E5%B1%B1%E5%8C%BA%E7%B2%A4%E6%B5%B7%E8%A1%97%E9%81%93%E7%A7%91%E6%8A%80%E5%9B%AD%E7%A4%BE%E5%8C%BA%E7%A7%91%E5%8F%91%E8%B7%AF1%E5%8F%B7%E5%AF%8C%E5%88%A9%E8%87%BB%E5%A4%A7%E5%8E%A63101&city=440300&geoobj=113.943065%7C22.540295%7C113.95496%7C22.54618&zoom=17.5',
              children: ( <
                span >
                <
                p >
                地点： 深圳市南山区粤海街道科技园社区科发路1号富利臻大厦3101 <
                /p> < /
                span >
              ),
            },
            {
              name: 'link2',
              href: '#',
              children: ( <
                img src = {
                  qrcode
                }
                alt = "二维码" / >
              ),
            },
          ],
        },
      },
      // {
      //   name: 'block2',
      //   xs: 24,
      //   md: 6,
      //   className: 'block',
      //   title: {
      //     children: ( <
      //       span >
      //       <
      //       p >
      //       <
      //       br / >
      //       <
      //       /p> < /
      //       span >
      //     ),
      //   },
      //   childWrapper: {
      //     children: [{
      //       href: '#',
      //       name: 'link0',
      //       children: ( <
      //         span >
      //         <
      //         p > 地图 < /p> < /
      //         span >
      //       ),
      //     }, ],
      //   },
      // },
    ],
  },
  copyrightWrapper: {
    className: 'copyright-wrapper'
  },
  copyrightPage: {
    className: 'home-page'
  },
  copyright: {
    className: 'copyright',
    children: ( <
      span > ©2021 by 深圳市时间量子科技有限公司 All Rights Reserved <
      a href = "https://beian.miit.gov.cn/#/Integrated/index"
      target = "_blank" > 备案号 粤ICP备2021033208号 < /a> < /
      span >
    ),
  },
};